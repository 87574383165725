export default function Header(): JSX.Element {
	const showAd = window.location.pathname == "/about" ? true : false;
	function goHome() {
		const query = new URLSearchParams(window.location.search).get("query");
		if (query !== null) {
			localStorage.setItem("lastSearch", JSON.stringify(query));
			window.location.href = "/";
		} else {
			window.location.href = "/";
		} //else
	} //goHome

	return (
		<div className="Header">
			<a
				onClick={goHome}
				id="headerAnchor">
				<h1 id="header"> Keikkakoodi </h1>
			</a>
			{showAd && (
				<div className="androidAd">
					<h2>
						Keikkakoodi nyt myös Android-sovelluksena. <br />{" "}
						<a
							id="androidInstallLink"
							href="https://github.com/keikkakoodi/Android/releases/download/latest/keikkakoodi.apk"
							target="_blank"
							rel="noopener noreferrer">
							Asenna!
						</a>
					</h2>
				</div>
			)}
		</div>
	);
}
