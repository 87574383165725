export default function Footer(): JSX.Element | null {
	const curPath = window.location.pathname;

	if (curPath !== "/about") {
		return (
			<footer>
				<a href="/about">
					<p id="abtLink">Mikä on keikkakoodi?</p>
				</a>
			</footer>
		); //return
	} else {
		return <></>;
	}
}
